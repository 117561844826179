.registerModalTitle {
    color: #1268b3;
    font-size: 1.65rem !important;
    font-weight: bold !important;
}

.registerModalContentText {
    color: #443c3c !important;
    font-size: 1.25rem !important;
    margin-bottom: 10px !important;
}

.registerModalBody {
    margin-left: 20px;
    margin-right: 20px;
    .registerModalBodyField {
        display: flex;
        gap: 20px;
    }
}

.registerModalBtn {
    font-size: 1.25rem !important;
}

.notification-green {
    background-color: #b2f3c2 !important;
    border: '1px solid #a8a6a6'
}

.register-user-card {
    margin: 80px 20px 0 120px;
    padding: 20px 10px;
    border-radius: 8px;
    background: #ffffff;
}

.register-user-card h3 {
  margin: 0px;
  color: #1268b3;
  font-size: 1.75rem !important;
  font-weight: bold !important;
}

.register-user-card p {
    margin: 0px;
    font-size: 1.25rem;
}

.register-btn-block {
    margin-top: 10px;
    justify-content: flex-end;
}