.service-card {
  margin-bottom: 10%;
  border-radius: 2%;
  .ant-card-actions :hover {
    background-color: #2e4799;
    color: white;
  }
  .ant-card-actions li span {
    width: inherit;
    margin-top: 6%;
    font-size: x-large;
  }
  .ant-card-actions li {
    height: 8vh;
    margin: 0px;
  }
  .ant-card-body {
    text-align: center;
  }
}

.service-card:hover {
  transform: scale(1.1);
}

.service-container {
  position: inherit;
  transform: translate(40%, 5%);
  left: 60%;
  width: 70%;
  margin-bottom: 5%;
}
.service-meta {
  .ant-card-meta-title {
    font-size: x-large;
  }
}
