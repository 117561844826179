
.user-management-table {
  overflow: hidden;
  overflow-x: auto;
}

.user-management-table table {
  width: 100%;
}

.user-management-table table thead tr {
  background: #1268b3;
}

.user-management-table table thead tr th {
  color: #fff;
  padding: 11px 15px;
  font-weight: normal;
  font-family: MyriadPro-Semibold;

}

.user-management-table table tr td {
  padding: 11px 15px;
  font-weight: normal;
  font-family: MyriadPro-Regular;
  color: #333333;
  white-space: nowrap;
}

.user-management-table table tbody tr {
  border: 1px solid rgba(224, 224, 224, 1);
}

.user-management-table table tbody tr td button {
  border: none;
  font-size: 16px;
  background: #00aeef;
  color: #fff;
  border-radius: 5px;
  width: 83px;
  cursor: pointer;
  height: 28px;
}

.user-management-table table tbody tr td button[disabled] {
  color: #c6c6c6;
  background: #d4d8da;
}

.admin-actions .user-mngt-edit-btn{
  margin-right: 10px;
}

.userManagementPagination p {
  margin: 0;
}

.user-management-table {
  height: calc(100vh - 250px);
  overflow-y: auto;
}