.service-heading {
    display: flex;
    justify-content:space-between;
    .new-service-btn {
        margin-top: 7px;
        font-size: 1.25rem;
        padding-top: 1px;
    }
}

.serviceEditBtn {
    margin-right: 10px;
}
