.loader-container {
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 1500;
}

.loader-container img {
    margin-top: 24%;
    margin-left: 50%;
    width: 4%;
}

.loader-container img {
    animation: spin-animation 0.5s infinite;
    display: inline-block;
  }
  
  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
}

@media (max-width: 575.98px) {
    .loader-container img {
        margin-top: 70%;
        margin-left: 35%;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .loader-container img {
        margin-top: 55%;
        margin-left: 40%;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .loader-container img {
        margin-top: 40%;
        margin-left: 40%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
    .loader-container img {
        margin-top: 30%;
        margin-left: 45%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1500px) {
    .loader-container img {
        margin-top: 25%;
        margin-left: 45%;
    }
}

@media (width: 1024px) and (height: 600px) {
    .loader-container img {
        margin-top: 25%;
        margin-left: 45%;
    }
}

@media (width: 768px) and (height: 1024px) {
    .loader-container img {
        margin-top: 55%;
        margin-left: 45%;
    }
}

@media (width: 1024px) and (height: 1366px) {
    .loader-container img {
        margin-top: 55%;
        margin-left: 45%;
    }
}