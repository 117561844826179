

.plus-btn-block {
  position: fixed;
  bottom: 10px;
  right: 10px;
  .plus-btn {
    border: none;
  }
}

.card {
  margin: 5px 10px;
  // margin: 20px 20px 0 120px;
  padding: 20px 10px;
  border-radius: 8px;
}

.card-row-col h3 {
  font-family: MyriadPro-Regular;
  font-size: 22px;
  color: #333333de;
  margin: 0;
  white-space: nowrap;
}

.card-row {
  display: flex;
  padding: 0 15px 15px;
  justify-content: space-between;
}

.btn-download button {
  display: inline-block;
  background: #34aeef;
  border: none;
  color: #fff;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
}
