.ticketModalAttachmentText {
    margin-top: 15px !important;
    white-space: nowrap;
}

.ticketUploadBtn {
    height: 40px !important;
    margin-top: 5px !important;
}

.ticketFileNameSpan {
    margin-top: 15px !important;
    font-size: 1rem;
}

.priority-medium {
    color: #1268B3;
}

.priority-low {
    color: green;
}

.priority-high {
    color: #dcdc03;
}

.priority-urgent {
    color: red;
}

.ticket-ellipsis-btn {
    margin-right: 10px;
}

.ticketNumber {
    display: flex;
}