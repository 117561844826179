.amc-card {
  transform: translate(12%, 5%);
  .ant-card-head-title {
    text-align: center;
    font-size: xx-large;
    color: #3f7cca;
  }
}

.amc-card-slider {
  .amc-slider-col {
    transform: translate(15%, 15%);
    .ant-slider-rail {
      height: 7px;
    }
    .ant-slider-track {
      height: 7px;
    }
    .ant-slider-handle {
      width: 20px;
      height: 20px;
      margin-top: -7px;
    }
  }
}

.amc-card-slider p {
  font-size: 15px;
  transform: translate(25%, 15%);
}

.amc-plan-detail:hover {
  background: #3f7cca;
  transform: scale(1.1);
  h2,
  p {
    color: white;
  }
  .ant-card-meta-title {
    color: white;
  }
  .ant-card-meta-description {
    color: white;
  }
  .ant-btn:hover {
    background-color: white;
    color: black;
  }
}

.amc-plans {
  margin-top: 5%;
  text-align: -webkit-center;
  .amc-plan-detail {
    border-radius: 30px;
    .ant-card-meta-title {
      text-align: center;
      font-size: xx-large;
    }
    .ant-card-cover {
      text-align: -webkit-center;
      img {
        width: 70%;
      }
    }
    .ant-card-meta-description {
      text-align: -webkit-center;
      font-size: larger;
    }
    h2 {
      text-align: -webkit-center;
      font-size: xxx-large;
      margin-bottom: 0;
    }
    .amc-plan-divider {
      height: 2px;
    }
    .amc-plan-features {
      p {
        font-size: medium;
        text-align: -webkit-auto;
        color: black;
      }
    }
    .amc-plan-detail-button {
      text-align: -webkit-center;
      margin-top: 20%;
    }
    .ant-btn {
      background-color: #0000ff;
    }
  }
}
