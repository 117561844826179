.site-layout-content {    
  height: 80vh;
  margin-top: 85px;
  // background: #fff;
  background-image: url("../../home.png");
  background-repeat: no-repeat;
}
.layout-header {
  background-color: #ffffff !important;
  position: fixed;
  width: 100%;
  .logo {
    float: left;
    width: 15%;
    margin: 16px 24px 16px 0;
  }
  .header-menu {
    border: none;
  }
}
.layout-header {
  .ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
}

.layout {
  .ant-layout-header {
    height: 85px;
    z-index: 2;
  }
  ul li {
    font-size: x-large;
    font-family: monospace;
  }
  .ant-menu {
    margin-top: 1%;
    margin-left: 50%;
  }
}

.footerContainer {
  // margin-top: 70px;
  height: 20vh;
  background-color: blue;
  background-image: linear-gradient(#6c6cef, #9fbce7);
  color: #ffffff;
  .footerTop {
    display: none;
    height: 25vh;
  }
  .footerTop h1 {
    font-size: 35px;
    margin: 25px;
    color: #ffffff;
  }
  .footerBottom {
    display: flex;
    height: 15vh;
    .fListContainer {
      margin-top: 2%;
      width: 70%;
      display: flex;
      font-size: 20px;
      justify-content: space-around;
    }
    .footerList1 {
      margin-left: 25%;
    }
    .footerList3 p {
      color: #ffffff;
      font-size: 20px;
      font-weight: 800;
      margin: 0;
    }
  }
  .footerBottom img {
    margin-top: 3%;
    max-width: 27%;
    height: 7vh;
    margin-left: 20px;
  }
}

.layout-footer {
  z-index: 2;
  margin-top: 7%;
  padding: 0%;
}
.ant-layout-header {
  background: unset;
}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
  .user-img {
    width: 35px;
  }
}
